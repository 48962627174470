@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;700;900&display=swap");
@import url("./styles/User.css");
@import url("./styles/SampleCollecionAt.css");
@import url("./styles/selectMember.css");
@import url("./styles/text.css");
@import url("./styles/positions.css");
@import url("./styles/flex.css");
@import url("./styles/orderStatusBadge.css");
@import url("./styles/heightandwidth.css");

:root {
  --primary-color: #005bab;
  --secondary-color: #00aeef;
  --text-color: #2c2c2c;
  --color1: #F6F9FC;

  /* radiology theme colors */
  --rs-clr1: #EEFAFA;
  /* radiology theme colors */

  /* fetal med unit */
  --fmu-clr1: #d12e88;
  --fmu-clr2: #ffe3f2;
  /* fetal med unit */
}

* {
  color: var(--text-color);
  font-family: "Archivo", sans-serif;
  margin: 0;
  margin-bottom: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: inherit;
}
.border-red{border: 2px solid red;}
.clr-inherit{color: inherit;}

.btn-k-primary {
  border: none;
  padding: 8px 20px;
  color: white;
  border-radius: 5px;
  background-color: var(--primary-color);
  transition: 0.5s;
}
.btn-k-primary:hover {
  background-color: var(--secondary-color);
}
.btn-k-secondary {
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  color: white !important;
  background-color: var(--secondary-color) !important;
  transition: 0.5s;
}
.btn-k-secondary:hover {
  background-color: var(--primary-color) !important;
}
.cursor-pointer:hover {
  cursor: pointer;
}

.hover-bg-danger:hover {
  background-color: rgb(218, 0, 0) (218, 0, 0);
}
.hover-bg-primary:hover {
  background-color: var(--primary-color);
}
.hover-bg-secondary:hover {
  background-color: var(--secondary-color);
}

.bg_primary {
  background-color: var(--primary-color);
}
.bg-k-secondary {
  background-color: var(--secondary-color);
}
.bg_light2{
  background-color: #F6F9FC;
}

.opacity5{ opacity: 0.5; }
.opacity7{ opacity: 0.7; }

.p-6{padding: 4.5rem;}
.py-6{padding: 4.5rem 0;}

/* ------------------------------------ Section three ------------------------------------------ */
.secThree-img img {
  width: auto;
  max-width: 100%;
  object-fit: cover;
}

.secThree {
  background-color: rgb(255, 255, 255);
  /* margin-top: -10rem; */
  /* gap: 50px; */
  padding: 0;
  border-radius: 25px;
  /* justify-content: center;  */
  font-size: 1.2rem;
  box-shadow: rgba(1, 24, 58, 0.2) 0px 8px 24px;
  overflow: hidden;
}

.secThree-info {
  margin: auto 0;
  flex-direction: column;
  width: 40%;
  padding: 0 50px 0 50px;
}

.secThree-info hr {
  margin: auto 0;
  color: #005bab;
  /* flex-direction: column; */
}
.sti {
  padding: 25px 0 25px 0;
  gap: 15px;
}
.secThree-info p {
  font-weight: 500;
  color: #005bab;
}
.secThree-info img {
  width: 30px;
  color: red;
}

/* ------------------------------------------- Section Four ------------------------------------- */
.cards {
  justify-content: center;
}

/* ----------------------------------------- Section Five ------------------------------------------ */

.secFive {
  justify-content: center;
  text-align: center;
}
.s5-searchbox {
  justify-content: center;
  gap: 0;
  margin-bottom: 25px;
}
.s5-search {
  background-color: #ffffff;

  padding: 8px 24px;
  font-size: 1.2rem;
  color: #005bab;
  border-radius: 25px 0 0 25px;
}
.s5-searchBtn {
  background-color: #005bab;
  padding: 8px 24px;
  font-size: 1.2rem;
  color: #fff;
  border: 2px solid #005bab;
  border-radius: 0 25px 25px 0;
}
.s5-bookAtest {
  margin-top: 50px 0 25px 0;
}

/* ----------------------------------------- Health Condition Articles ------------------------------------------ */
.bottomlogbtn {
  padding: 0 5px;
  font-weight: 700;
  background: none;
  color: blue;
  border: none;
}
.bottomlogbtn:hover {
  color: red;
}

.popupclosebtn {
  width: 35px;
  height: 35px;
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  border: none;
  border-radius: 0 0 0 5px;
}
/* LogPopup css */

/* cards increment, decrement styling */
.qntCntWrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .quantDisp {
    display: inline-block;
    text-align: center;
    font-weight: bold;
    color: #005bab;
    padding: 3px 6px;
    width: calc(100% - 60px);
    width: 46px;
    height: 28px;
    background-color: #fff;
    border-top: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
  }
  .plusminBtn {
    width: 28px;
    height: 28px;
    background: linear-gradient(#fff, #f9f9f9);
    display: inline-block;
    border: 1px solid #c2c2c2;
    cursor: pointer;
    font-size: 16px;
    padding-top: 1px;
    line-height: 1;
  }
  .plsBtn {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  .mnsBtn {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
}

/* cards increment, decrement styling */
